<template>
  <div>
    <el-card>
      <div class='header'>
        <el-form :inline='true' :model='queryForm' class='demo-form-inline'>
          <el-form-item>
            <el-select
              v-model='queryForm.userType'
              clearable
              placeholder='请选择用户类型'
            >
              <el-option
                v-for='item in userType'
                :key='item.value'
                :label='item.label'
                :value='item.value'
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model='queryForm.userSource'
              clearable
              placeholder='请选择用户来源'
            >
              <el-option
                v-for='item in userSource'
                :key='item.value'
                :label='item.label'
                :value='item.value'
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model='queryForm.userRating'
              clearable
              placeholder='请选择意向度'
            >
              <el-option label='未知' :value='0'></el-option>
              <el-option label='★' :value='1'></el-option>
              <el-option label='★★' :value='2'></el-option>
              <el-option label='★★★' :value='3'></el-option>
              <el-option label='★★★★' :value='4'></el-option>
              <el-option label='★★★★★' :value='5'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model='queryForm.sex'
              clearable
              placeholder='请选择性别'
            >
              <el-option label='男' :value='1'></el-option>
              <el-option label='女' :value='2'></el-option>
              <el-option label='保密' :value='3'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model='queryForm.isBuy'
              clearable
              placeholder='请选择是否投资'
            >
              <el-option label='未投资' :value='false'></el-option>
              <el-option label='已投资' :value='true'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model='queryForm.isAddWechat'
              clearable
              placeholder='请选择是否添加微信'
            >
              <el-option label='未添加微信' :value='false'></el-option>
              <el-option label='已添加微信' :value='true'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-permission="['sys:sysUser:get']"
              v-model='queryForm.sysUserId'
              clearable
              filterable
              @visible-change='selectSysUsersChange'
              placeholder='请选择管理员'>
              <el-option label='未分配' :value='0'></el-option>
              <el-option
                v-for='item in sysUsers'
                :key='item.userId'
                :label='item.nickname'
                :value='item.userId'>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model='queryForm.phone' placeholder='手机号'></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model='queryForm.phoneAddress'
              placeholder='归属地'
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model='queryForm.fullName'
              placeholder='客户姓名'
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model='queryForm.idNumber'
              placeholder='身份证号'
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model='queryForm.email' placeholder='邮箱'></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model='queryForm.qq' placeholder='QQ'></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model='queryForm.wechat' placeholder='微信'></el-input>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model='registerTime'
              type='daterange'
              range-separator='-'
              start-placeholder='用户注册开始日期'
              end-placeholder='用户注册结束日期'
              :unlink-panels='true'
              @calendar-change='selectRegisterTimeCalendarChange'
              @change='timeChangeHandle'
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-date-picker
              v-model='loginTime'
              type='daterange'
              range-separator='-'
              start-placeholder='用户登录开始日期'
              end-placeholder='用户登录结束日期'
              :unlink-panels='true'
              @calendar-change='selectLoginTimeCalendarChange'
              @change='timeChangeHandle'
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-date-picker
              v-model='followRecordTime'
              type='daterange'
              range-separator='-'
              start-placeholder='跟进记录开始日期'
              end-placeholder='跟进记录结束日期'
              :unlink-panels='true'
              @calendar-change='selectFollowRecordTimeCalendarChange'
              @change='timeChangeHandle'
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-select
              v-model='queryForm.sortType'
              clearable
              placeholder='请选择排序'
            >
              <el-option
                label='最近登陆时间由近及远'
                value='loginTimeDesc'
              ></el-option>
              <el-option
                label='最近登录时间由远及近'
                value='loginTimeAsc'
              ></el-option>
              <el-option
                label='最新跟进时间由近及远'
                value='followRecordTimeDesc'
              ></el-option>
              <el-option
                label='最新跟进时间由远及近'
                value='followRecordTimeAsc'
              ></el-option>
              <el-option
                label='注册时间由近及远'
                value='registerTimeDesc'
              ></el-option>
              <el-option
                label='注册时间由远及近'
                value='registerTimeAsc'
              ></el-option>
              <el-option
                label='最近打款时间由近及远'
                value='paymentTimeDesc'></el-option>
              <el-option
                label='最近打款时间由远及近'
                value='paymentTimeAsc'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' @click='getListData'>查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div
        class='tool'
        style='background: #fafafa;display: flex;padding: 10px;border: 1px solid #ebeef5;border-bottom: 0;'
      >
        <div>
          <el-button size='small' type='primary' @click='addClick' v-permission="['sys:user:add']"
          >添加用户
          </el-button>
          <!--          <el-button size='small' type='danger'>删除</el-button>-->
          <el-button size='small' type='success' @click='assignUserClick' v-permission="['sys:user:assign']">分配给员工
          </el-button>
        </div>
        <div style='flex: 1;text-align: right;'>
          <el-button size='small' type='primary' @click='getListData' plain
          >刷新列表
          </el-button>
        </div>
      </div>
      <div class='table'>
        <el-table
          border
          v-loading='loading'
          :header-cell-style="{
            background: '#fafafa',
            color: '#333333',
            padding: '4px 0'
          }"
          :cell-style="{ padding: '4px 0' }"
          :data='tableData'
          style='width: 100%'
          @selection-change='handleSelectionChange'
        >
          <el-table-column type='selection' width='55' />
          <el-table-column type='expand'>
            <template #default='props'>
              <el-descriptions title='' style='padding-left: 60px '>
                <el-descriptions-item label='用户ID：'
                >{{ props.row.id }}
                </el-descriptions-item>
                <el-descriptions-item label='昵称：'
                >{{ props.row.nickname }}
                </el-descriptions-item>
                <el-descriptions-item label='姓名：'
                >{{ props.row.fullname }}
                </el-descriptions-item>
                <el-descriptions-item label='身份证号：'
                >{{ props.row.idNumber }}
                </el-descriptions-item>
                <el-descriptions-item label='身份证地址：'
                >{{ props.row.idAddress }}
                </el-descriptions-item>
                <el-descriptions-item label='生日：'
                >{{ props.row.birthday }}
                </el-descriptions-item>
                <el-descriptions-item label='邮箱：'
                >{{ props.row.email }}
                </el-descriptions-item>
                <el-descriptions-item label='QQ：'
                >{{ props.row.qq }}
                </el-descriptions-item>
                <el-descriptions-item label='微信：'
                >{{ props.row.wechat }}
                </el-descriptions-item>
                <el-descriptions-item label='账户注册时间：'
                >{{ props.row.createTime }}
                </el-descriptions-item>
                <el-descriptions-item label='最新登录时间：'
                >{{ props.row.loginTime }}
                </el-descriptions-item>
                <el-descriptions-item label='最新跟进时间：'
                >{{ props.row.followRecordTime }}
                </el-descriptions-item>
                <el-descriptions-item label='备注：'>
                  {{ props.row.remark }}
                </el-descriptions-item>
              </el-descriptions>
            </template>
          </el-table-column>
          <el-table-column property='createTime' label='注册时间' width='100'>
            <template #default='scope'>
              {{ formatDate(scope.row.createTime) }}
            </template>
          </el-table-column>
          <el-table-column property='userRating' label='意向度' width='145'>
            <template #default='scope'>
              <el-rate v-model='scope.row.userRating' disabled></el-rate>
            </template>
          </el-table-column>
          <el-table-column property='userType' label='用户类型' width='80'>
            <template #default='scope'>
              <template v-if='scope.row.userType == 1'>直客</template>
              <template v-else-if='scope.row.userType == 2'>渠道客户</template>
              <template v-else-if='scope.row.userType == 3'>理财经理</template>
              <template v-else-if='scope.row.userType == 4'>项目方</template>
              <template v-else-if='scope.row.userType == 5'>其他</template>
            </template>
          </el-table-column>
          <el-table-column property='userSource' label='用户来源' width='80'>
            <template #default='scope'>
              <template v-if='scope.row.userSource == 1'>注册</template>
              <template v-else-if='scope.row.userSource == 2'>后台</template>
              <template v-else-if='scope.row.userSource == 3'>其他</template>
            </template>
          </el-table-column>
          <el-table-column property='fullname' label='姓名' width='68' />
          <el-table-column property='phone' label='手机号' width='116'>
            <template #default='scope'>
          <span @click='showPhoneNumber(scope.row)'
                v-if='scope.row.showPhoneNumber != true'>{{ phoneFormat(scope.row.phone) }}</span>
              <span v-else>{{ scope.row.phone }}</span>
            </template>
          </el-table-column>
          <el-table-column property='phoneAddress' label='归属地' />
          <el-table-column property='isBuy' label='投资'>
            <template #default='scope'>
              <el-tag type='success' v-if='scope.row.isBuy == 1'>已投</el-tag>
              <el-tag type='info' v-if='scope.row.isBuy == 0'>未投</el-tag>
            </template>
          </el-table-column>
          <el-table-column property='loginTime' label='最近登录' width='100'>
            <template #default='scope'>
              {{
                scope.row.loginTime === null
                  ? '未登录'
                  : formatDate(scope.row.loginTime)
              }}
            </template>
          </el-table-column>
          <el-table-column property='createTime' label='最近打款' width='100'>
            <template #default='scope'>
              <template v-if='scope.row.paymentTime'>{{ formatDate(scope.row.paymentTime) }}</template>
            </template>
          </el-table-column>
          <el-table-column property='followRecordTime' label='最新跟进记录' width='110'>
            <template #default='scope'>
              <span
                v-if='scope.row.userFollowRecord!=null'>
                <span style='color: #666666'>[{{ scope.row.followRecordTime }}]</span>
                {{ scope.row.userFollowRecord.content }}</span>
            </template>
          </el-table-column>
          <el-table-column property='wechat' label='微信' />
          <el-table-column property='sysUser.nickname' label='管理员' width='80' />
          <el-table-column fixed='right' label='操作' width='120'>
            <template #default='scope'>
              <el-button type='text' @click='showFollowRecordClick(scope.row)'
              >跟进
              </el-button>
              <el-dropdown>
                <span
                  class='el-dropdown-link'
                  style='color: #409eff;margin-left: 10px;'
                >
                  管理
                  <el-icon class='el-icon--right'>
                    <arrow-down />
                  </el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click='editClick(scope.row)' v-permission="['sys:user:update']"
                    >修改信息
                    </el-dropdown-item>
                    <el-dropdown-item @click='goReservePage(scope.row)'
                    >预约管理
                    </el-dropdown-item>
                    <el-dropdown-item @click='goOrderPage(scope.row)'
                    >订单管理
                    </el-dropdown-item>
                    <el-dropdown-item @click='showFavoritesClick(scope.row)'
                    >收藏信息
                    </el-dropdown-item>
                    <el-dropdown-item @click='showSetLoginPhoneClick(scope.row)' v-permission="['sys:user:setUserLoginPhone']"
                    >变更登录手机号
                    </el-dropdown-item>
                    <el-dropdown-item
                      divided
                      @click='delClick(scope.row)'
                      style='color: crimson'
                      v-if='false'
                    >删除用户
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style='text-align: center;padding-top: 20px;'>
        <el-pagination
          layout='total, sizes, prev, pager, next, jumper'
          :page-sizes='[10, 20, 50, 100, 200, 500]'
          :page-size='queryForm.limit'
          :current-page='queryForm.page'
          :total='total'
          @size-change='handleSizeChange'
          @current-change='handleCurrentChange'
        >
        </el-pagination>
      </div>
    </el-card>

    <add-user
      v-if='addUserDialogVisible'
      v-model='addUserDialogVisible'
      @close='getListData'
    ></add-user>

    <edit-user
      v-if='editUserDialogVisible'
      v-model='editUserDialogVisible'
      :id='selectedUserId'
      @close='getListData'
    ></edit-user>

    <user-favorites
      v-if='showFavoritesDialogVisible'
      v-model='showFavoritesDialogVisible'
      :id='selectedUserId'>
    </user-favorites>

    <user-follow-record
      v-if='showFollowRecordDialogVisible'
      v-model='showFollowRecordDialogVisible'
      :id='selectedUserId'
      @close='getListData'></user-follow-record>

    <assign-user
      v-if='assignUserDialogVisible'
      v-model='assignUserDialogVisible'
      :ids='selectItemIds'>
    </assign-user>

    <set-user-login-phone
      v-if='showSetLoginPhoneDialogVisible'
      v-model='showSetLoginPhoneDialogVisible'
      :id='selectedUserId'
      @close='getListData'>
    </set-user-login-phone>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { getUserPage } from '@/api/user'
import { getSysUserList } from '@/api/sys-user'
import { ArrowDown } from '@element-plus/icons'
import dayjs from 'dayjs'
import AddUser from './components/AddUser'
import EditUser from './components/EditUser'
import UserFavorites from './components/UserFavorites'
import UserFollowRecord from './components/UserFollowRecord'
import AssignUser from './components/AssignUser'
import router from '@/router'
import SetUserLoginPhone from './components/SetUserLoginPhone.vue'

const queryForm = ref({
  page: 1,
  limit: 10,
  sort: 'createTime',
  order: 'desc',
  sysUserId: '', // 管理员id
  userType: '', // 用户类型
  userSource: '', // 用户来源
  userRating: '', // 意向度
  isAddWechat: '', // 是否添加微信
  isBuy: '', // 是否投资
  phone: '', // 手机号
  phoneAddress: '', // 归属地
  fullName: '', // 姓名
  idNumber: '', // 身份证号
  sex: '', // 性别
  email: '', // 邮箱
  qq: '', // qq
  wechat: '', // 微信
  sortType: '', // 排序类型
  registerTimeStart: '', // 注册时间范围
  registerTimeEnd: '', // 注册时间范围
  loginTimeStart: '', // 登录时间范围
  loginTimeEnd: '', // 登录时间范围
  followRecordTimeStart: '', // 跟进记录时间范围
  followRecordTimeEnd: '' // 跟进记录时间范围
})
const registerTime = ref([])
const selectRegisterTimeCalendarChange = date => {
  queryForm.value.registerTimeStart = formatDate(date[0])
  queryForm.value.registerTimeEnd = formatDate(date[1])
}
const loginTime = ref([])
const selectLoginTimeCalendarChange = date => {
  queryForm.value.loginTimeStart = formatDate(date[0])
  queryForm.value.loginTimeEnd = formatDate(date[1])
}
const followRecordTime = ref([])
const selectFollowRecordTimeCalendarChange = date => {
  queryForm.value.followRecordTimeStart = formatDate(date[0])
  queryForm.value.followRecordTimeEnd = formatDate(date[1])
}

const timeChangeHandle = () => {
  if (registerTime.value == null) {
    queryForm.value.registerTimeStart = ''
    queryForm.value.registerTimeEnd = ''
  }
  if (loginTime.value == null) {
    queryForm.value.loginTimeStart = ''
    queryForm.value.loginTimeEnd = ''
  }
  if (followRecordTime.value == null) {
    queryForm.value.followRecordTimeStart = ''
    queryForm.value.followRecordTimeEnd = ''
  }
}

// 用户来源
const userSource = [
  {
    label: '注册',
    value: 1
  },
  {
    label: '后台',
    value: 2
  },
  {
    label: '其他',
    value: 3
  }
]
// 用户类型
const userType = [
  {
    label: '直客',
    value: 1
  },
  {
    label: '渠道客户',
    value: 2
  },
  {
    label: '理财经理',
    value: 3
  },
  {
    label: '项目方',
    value: 4
  },
  {
    label: '其他',
    value: 5
  }
]

/**
 * 表格数据相关
 */

const loading = ref(false)
const tableData = ref([])
const total = ref(0)
// 获取数据的方法
const getListData = async () => {
  loading.value = true
  const result = await getUserPage(queryForm.value)
  tableData.value = result.list
  total.value = result.total
  loading.value = false
}
getListData()

/**
 * 获取管理员列表
 */
const sysUsers = ref([])
const getSysUsers = async () => {
  sysUsers.value = await getSysUserList()
}
// 下拉框出现时候再获取
const selectSysUsersChange = (val) => {
  if (val === true && sysUsers.value.length <= 0) {
    getSysUsers()
  }
}

/**
 * 分页数量改变事件
 */
const handleSizeChange = val => {
  queryForm.value.limit = val
  getListData()
}
const handleCurrentChange = val => {
  queryForm.value.page = val
  getListData()
}

/**
 * 选中的列表id数组
 */
const selectItemIds = ref([])
const handleSelectionChange = val => {
  selectItemIds.value = []
  val.forEach(row => {
    selectItemIds.value.push(row.id)
  })
}

/**
 * 日期格式化

 * @param date
 * @returns {string}
 */
const formatDate = date => {
  return dayjs(date).format('YYYY-MM-DD')
}

/**
 * 添加用户点击事件
 */
const addUserDialogVisible = ref(false)
const addClick = () => {
  addUserDialogVisible.value = true
}

/**
 * 修改用户点击事件
 */
const selectedUserId = ref(0)
const editUserDialogVisible = ref(false)
const editClick = row => {
  selectedUserId.value = row.id
  editUserDialogVisible.value = true
}

/**
 * 跳转到用户预约订单列表
 */
const goReservePage = (row) => {
  router.push('/userReserve/manage/' + row.id)
}

/**
 * 跳转到用户产品订单列表
 */
const goOrderPage = (row) => {
  router.push('/userOrder/manage/' + row.id)
}

/**
 * 显示用户收藏
 */
const showFavoritesDialogVisible = ref(false)
const showFavoritesClick = row => {
  selectedUserId.value = row.id
  showFavoritesDialogVisible.value = true
}

/**
 * 显示设置用户手机号界面
 */
const showSetLoginPhoneDialogVisible = ref(false)
const showSetLoginPhoneClick = row => {
  selectedUserId.value = row.id
  showSetLoginPhoneDialogVisible.value = true
}

/**
 * 显示跟进记录
 */
const showFollowRecordDialogVisible = ref(false)
const showFollowRecordClick = row => {
  selectedUserId.value = row.id
  showFollowRecordDialogVisible.value = true
}

/**
 * 分配用户
 */
const assignUserDialogVisible = ref(false)
const assignUserClick = () => {
  assignUserDialogVisible.value = true
}

/**
 * 手机号格式化
 * @param phone
 * @returns {string|*}
 */
const phoneFormat = (phone) => {
  try {
    const start = phone.slice(0, 3)
    const end = phone.slice(-4)
    return start + '****' + end
  } catch (ex) {
    console.log(ex)
  }
  return phone
}

const showPhoneNumber = (row) => {
  for (let j = 0; j < tableData.value.length; j++) {
    tableData.value[j].showPhoneNumber = false
  }
  row.showPhoneNumber = true
}
</script>

<style lang='scss' scoped></style>
